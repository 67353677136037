import React from "react";
import thumb from "../../assets/images/forensic.jpeg";
import { Link, useLocation } from "react-router-dom";
function DetailsService() {
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-category-widget">
									<ul>
										<li>
											<Link to="/ethical-hacking">
												<i className="fal fa-link"></i>Ethical Hacking
											</Link>
										</li>
										<li>
											<Link to="/forensic-investigation">
												<i className="fal fa-link"></i>Forensic Investigation
											</Link>
										</li>
										<li>
											<Link to="/asset-recovery">
												<i className="fal fa-link"></i>Asset Recovery
											</Link>
										</li>
										<li>
											<Link to="/cyber-security">
												<i className="fal fa-link"></i>Cyber Security
											</Link>
										</li>
									</ul>
								</div>
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="thumb">
									<img src={thumb} alt="" />
								</div>
								<div className="content">
									<h3 className="title">ENVIRONMENTAL ANALYSIS</h3>
									<p>
										The first steps in solving a cybercrime is to identify,
										access, track, and analyze data. This data, which could be
										physical or digital, gives us a comprehensive insight into
										the criminal(s) and victim. We also conduct a forensic
										accounting, which helps us understand the motive behind the
										cybercrime and loss of assets.
									</p>
									<p>
										Then, we retrieve all crucial information traceable to the
										crime, including digital thumbprints, digital forensic data,
										digital thumbprints, illicit transactions, and other
										imperative data that might help with the investigation.
										PolygonDecipher’s multipurpose cybersecurity kit QUANTUM 3.5
										is used to implements an all-inclusive analysis on all
										identified and retrieved data.
									</p>
									<h3 className="title">DEVELOPMENTAL PLANNING</h3>
									<p>
										Following a comprehensive environmental analysis on
										retrieved data, we will use our expertise to design
										strategic recovery structure to retrieve all lost data,
										funds, and assets. Our threshold team will also offer the
										best advice on the next steps to take to ensure you recover
										all stolen assets.
									</p>
									<h3 className="title">{`EXECUTION & EVALUATION`}</h3>
									<p>
										This is the final and most crucial stage in a forensic
										investigation. In this stage, we have identified the crime
										and developed the most effective methods to retrieve stolen
										funds and protect you against future cyber frauds. Our
										experienced team will carry out a careful and effective
										implementation of assets recovery strategies, until all
										stolen funds and assets are recovered successfully.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsService;
