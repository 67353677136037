import React from 'react';
import featureThumb from '../../assets/images/features-thumb-2.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function FeaturesHomeTwo() {
    return (
        <>
            <section className="appie-features-area-2 pt-90 pb-100" id="features">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">
                                Assets Recovery Process<br />
                                </h3>
                                <p>
                                We guarantee 100% transparency as we guide you through the recovery process
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="appie-features-box-item">
                                    <h4 className="title">Examine the situation</h4>
                                    <p>The first step to a successful recovery is to carefully analyze data and situations surrounding the stolen assets.</p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Identify and Collect Evidence</h4>
                                    <p>After a successful environmental analysis, data identification and collection of evidence is carried out for further analyses.</p>
                                </div>
                                <div className="appie-features-box-item item-3">
                                    <h4 className="title">Critical Analysis and Counter-Action</h4>
                                    <p>This is where data forecasting and segregation comes in. We will perform a critical analysis of collected evidence to shed more light on the fraud and execute strategic plans for recovery. </p>
                                </div>
                                <div className="appie-features-box-item item-1">
                                    <h4 className="title">Recover your Assets</h4>
                                    <p>After we have recovered all your lost assets, we will deliver them to you and provide guidelines to avoid future threats.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} alt=""  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default FeaturesHomeTwo;
