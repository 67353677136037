import React from "react";
import thumb from "../../assets/images/ethical.jpeg";
import { Link, useLocation } from "react-router-dom";
function DetailsService() {
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-category-widget">
									<ul>
										<li>
											<Link to="/ethical-hacking">
												<i className="fal fa-link"></i>Ethical Hacking
											</Link>
										</li>
										<li>
											<Link to="/forensic-investigation">
												<i className="fal fa-link"></i>Forensic Investigation
											</Link>
										</li>
										<li>
											<Link to="/asset-recovery">
												<i className="fal fa-link"></i>Asset Recovery
											</Link>
										</li>
										<li>
											<Link to="/cyber-security">
												<i className="fal fa-link"></i>Cyber Security
											</Link>
										</li>
									</ul>
								</div>
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="thumb">
									<img src={thumb} alt="" />
								</div>
								<div className="content">
									<h3 className="title">SOCIAL ENGINEERING RESPONSE</h3>
									<p>
										Social Engineering is a broad technique used by cyber
										criminals to psychologically and emotionally manipulate
										unsuspecting targets to release sensitive information, or
										gain access to restricted data. These dubious actions by
										unethical hackers is primarily to extort funds or steal
										classified information from their victims.
									</p>
									<p>
										Some of the most common types of social engineering
										manipulations include baiting, Trojan, scam calls,
										scareware, spear phishing, advanced fee fraud, pretexting,
										tech support scams, among others. To successfully conduct a
										Social Engineering Response (SER), one needs to be highly
										intellectual and current in the ways of these cyber
										criminals. Our experts at PolygonDecipher help users to
										identify and strategically respond to these threats. Using a
										reverse engineering approach, we can determine the degree of
										potential damage posed by the cyber threat, and attack them
										accordingly. We also suggest specific strategies to ensure a
										lasting solution.
									</p>
									<h3 className="title">
										VULNERABILITY ASSESSMENT AND PENETRATION TESTING
									</h3>
									<p>
										Vulnerability assessment and pen testing are two mutually
										exclusive terms that perform very similar functions.
										Vulnerability assessment is the process of identifying,
										analyzing, and containing the risks and flaws of an
										Information System. Without vulnerability assessment and
										pen-test, businesses run the risks of exposing themselves to
										cyberattacks and breaches, which may lead to financial loss
										and poorly project the image of the organization.
									</p>
									<p>
										Polygon’s Vulnerability Assessment and Pen-Test analysis
										give organizations the ability to flaws, cyber security
										risks, breaches, and effectively manage these
										vulnerabilities to protect assets and strengthen Information
										System against future breaches.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsService;
