import React from "react";
import thumb from "../../assets/images/cyber.jpeg";
import { Link, useLocation } from "react-router-dom";
function DetailsService() {
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-category-widget">
									<ul>
										<li>
											<Link to="/ethical-hacking">
												<i className="fal fa-link"></i>Ethical Hacking
											</Link>
										</li>
										<li>
											<Link to="/forensic-investigation">
												<i className="fal fa-link"></i>Forensic Investigation
											</Link>
										</li>
										<li>
											<Link to="/asset-recovery">
												<i className="fal fa-link"></i>Asset Recovery
											</Link>
										</li>
										<li>
											<Link to="/cyber-security">
												<i className="fal fa-link"></i>Cyber Security
											</Link>
										</li>
									</ul>
								</div>
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="thumb">
									<img src={thumb} alt="" />
								</div>
								<div className="content">
									<h3 className="title">CYBER RESILIENCE</h3>
									<p>
										With the alarming increase in cyber fraud, it is a necessity
										for businesses to strengthen their security. Email,
										websites, and other digital communication platforms have
										contributed immensely to the increase in cybercrime. While
										these platforms are the primary channels for business
										correspondence, they are also the major source of
										infiltration of malicious criminals. Traditional security
										measures have proven to be insufficient in curbing these
										crimes, and despite the advanced security measures adopted
										by some businesses to strengthen their defenses, these cyber
										attackers continue to deploy newer techniques to breach the
										security of organizations.
									</p>
									<p>
										This is why at PolygonDecipher, we have developed strategies
										measures to frustrate the efforts of these scammers. We
										provide sustainable solutions to swiftly recover stolen data
										and protect future data from breach. We also equip our
										clients with the necessary tools to identify and combat
										future attacks.
									</p>
									<h3 className="title">RISK MITIGATION AND ANALYSIS</h3>
									<p>
										This involves the analysis of possible risks in order to
										prevent loss of funds and fall victims of internet
										fraudsters. Risk mitigation is a cogent value of
										cybersecurity. In fact, risk mitigation is the core of
										cybersecurity. It also serves as the fundamentals of the
										NIST Framework for Improving Critical Infrastructure
										Cybersecurity. The tenets of the NIST framework help us
										approach risks practically and address design flaws, rather
										than speculating about risks.
									</p>
									<p>
										Our Risk Management Experts consist of professionals who
										have undergone critical training and intensive risk
										analytical sessions to verify the design vulnerabilities
										that a system is susceptible to.
									</p>
									<h3 className="title">UNIFIED THREAT MANAGEMENT (UTM)</h3>
									<p>
										Unified Threat Management is the process of providing
										multiple security features and services in a single service,
										in order to protect users from security threats, viruses,
										spyware, and network attacks in a simplified manner. It
										helps users focus on protecting system and data from a
										single point of protection, rather than focusing on multiple
										security solutions. PolygonDecipher’s UTM has received
										global recognition, as the configuration and conservation
										saves time, money, and manual labor. It has also proven to
										be efficient compared to managing multiple points of
										protection.
									</p>
									<p>
										In addition, our UTM helps in content monitoring, intrusion
										detection and prevention, as well as provides anti-spam
										defense.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsService;
