import React,{Component} from 'react';
import blogOne from '../../assets/images/blog-1.jpeg';
import blogTwo from '../../assets/images/blog-2.webp';
import blogThree from '../../assets/images/blog-3.jpeg';
import Axios from "axios";
import moment from 'moment';
import { Link } from 'react-router-dom';

export class BlogHomeOne extends Component { 
    state ={
        post: '',
    }
    mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@polygondecipher";

    componentDidMount() {
        Axios.get(this.mediumURL)
    
          .then((data) => {
            // console.log(data.data)
            const avatar = data.data.feed.image;
            const profileLink = data.data.feed.link;
            const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
            const posts = res.filter(item => item.categories.length > 0);
            const title = data.data.feed.title; 
            this.setState({
                post: res
            })
          })
          .catch((e) => {
            this.setState({ error: e.toJSON() })
            console.log(e);
          });
      }
    render(){  
        // console.log(this.state.post.length > 0 && this.state.post[0].thumbnail);
    return (
        <>
            <section className="appie-blog-area pt-90 pb-95"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Popular posts</h3>
                                <p>Different layouts and styles for team sections.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={this.state.post.length > 0 && this.state.post[0].thumbnail} alt="" width={'20px'} />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>{moment(this.state.post.length > 0 && this.state.post[0].pubDate).format("MMMM Do, YYYY")}</li>
                                            <li>
                                                <a href="#">Popular Post</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[0].title}`}>
                                       {this.state.post.length > 0 && this.state.post[0].title}
                                        </Link>
                                    </h3>
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[0].title}`}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={this.state.post.length > 0 && this.state.post[1].thumbnail} alt="" width={'20px'} />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>{moment(this.state.post.length > 0 && this.state.post[1].pubDate).format("MMMM Do, YYYY")}</li>
                                            <li>
                                                <a href="#">Popular Post</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[1].title}`}>
                                       {this.state.post.length > 0 && this.state.post[1].title}
                                        </Link>
                                    </h3>
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[1].title}`}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={this.state.post.length > 0 && this.state.post[2].thumbnail} alt="" width={'20px'} />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>{moment(this.state.post.length > 0 && this.state.post[2].pubDate).format("MMMM Do, YYYY")}</li>
                                            <li>
                                                <a href="#">Popular Post</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[2].title}`}>
                                        {this.state.post.length > 0 && this.state.post[2].title}
                                        </Link>
                                    </h3>
                                    <Link to={`/news/${this.state.post.length > 0 && this.state.post[2].title}`}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    );
}
}

export default BlogHomeOne;
