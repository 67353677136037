import React from 'react';
import iconOne from '../../assets/images/icon/1.png';
import iconTwo from '../../assets/images/icon/2.png';
import iconThree from '../../assets/images/icon/3.png';
import iconFour from '../../assets/images/icon/4.png';
import { Link } from 'react-router-dom';
function ServicesHomeThree() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div>
                        {/* <div className="col-lg-6"> */}
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">POLYGONDECIPHER SERVICES</h3> 
                                <h5 style={{color: '#726f84' , marginTop:'12px'}}>WE SERVE AS THE THRESHOLD AGAINST CYBERCRIME</h5>
                                <p>
                                PolygonDecipher is well-equipped with cutting-edge technology, and a Threshold Team that works across different time zones to ensure operational productivity. Our Threshold Team comprises seasoned experts who know how to extensively investigate cybercrimes, employ effective measures to recover lost assets and protect our clients from future cyber-attacks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={iconOne} alt="" />
                                </div>
                                <h4 className="appie-title">ETHICAL HACKING</h4>
                                <p>Using a reverse engineering procedure, our Cyber-Threshold team performs Social Engineering Response, Penetration Testing, and Vulnerability Assessment. </p>
                                <Link to="/ethical-hacking">
                                    Read More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={iconTwo} alt="" />
                                </div>
                                <h4 className="appie-title">FORENSIC INVESTIGATION</h4>
                                <p>Gaining a thorough understanding of a cybercrime act is the key to unraveling it. It is pertinent to gather substantial evidence and digital forensic data about the con in order to develop a strategic recovery procedure. </p>
                                <Link to="/forensic-investigation">
                                    Read More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={iconThree} alt="" />
                                </div>
                                <h4 className="appie-title">ASSET RECOVERY</h4>
                                <p>Polygon Decipher provides an opportunity for you to recover stolen assets from various internet frauds, including cryptocurrencies, investments, and conventional scams. </p>
                                <Link to="/asset-recovery">
                                    Read More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src={iconFour} alt="" />
                                </div>
                                <h4 className="appie-title">CYBER SECURITY</h4>
                                <p>We devise technical strategies, which include Unified Threat Management, Risk Analysis, Cyber resilience, among others, which help you or your organization to detect, neutralize, and reduce cyber security breaches.</p>
                                <Link to="/cyber-security">
                                    Read More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeThree;
