import React from 'react';
import { Link } from 'react-router-dom';
import HeroDot from '../../assets/images/hero-dot.png';
import heroThumb from '../../assets/images/hero-thumb-5.png';
import shape from '../../assets/images/shape/shape-10.png';

function HeroHomeFive() {
    return (
        <>
            <section className="appie-hero-area appie-hero-4-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="appie-hero-content appie-hero-content-4">
                                <h4 className="appie-title">Need to reclaim your lost assets? </h4> 
                                <p style={{paddingBottom: '15px'}}><h6>Look no further!</h6></p>
                                <p>
                                 At Polygondecipher, we specialize in Cybercrime Investigation, Assets Recovery, and Cyber Investigation.  
                                </p> 
                                
                             
                                <Link to="/start" className="main-btn">
                                    Speak with Us
                                </Link>
                                
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="appie-hero-thumb appie-hero-thumb-4">
                                <img src={heroThumb} alt="" />
                                <div className="hero-dot">
                                    <img src={HeroDot} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shape} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeFive;
