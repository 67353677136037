import React, { Component } from "react"
import Disqus from "disqus-react"

export default class HomeDisqus extends Component {
  render() {
    const disqusShortname = "polygondecipher-org"
    const disqusConfig = {
      url: "http://localhost:3002",
      identifier: "dsq-count-scr",
      title: "Polygondecypher"
    }

    return (
      <div className="article-container">
        <Disqus.DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
        />
      </div>
    )
  }
}