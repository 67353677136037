import React from 'react';
import aboutThumb from '../../assets/images/about-thumb.png';
import { Link } from 'react-router-dom'; 
function AboutHomeTwo() {
    return (
        <>
            <section className="appie-about-area mb-100">
                <div  >
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-thumb">
                                            <img width={'40%'}  src={aboutThumb} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-about-content">
                                            <h3 className="title">
                                                Why work with us ?
                                            </h3>
                                            <p>
                                            Data protection, cyber awareness, and customer satisfaction are the core values of our organization.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <br />
                                                    <p className='txt'>
                                                    Our threshold team consists of seasoned experts with proven records of speedy assets recovery, ethical hacking, forensic investigation and cybersecurity.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <br />
                                                    <p className='txt'>
                                                    We are passionate about you, and with our avantgarde technology and professionalism, we will recover and provide maximum cybersecurity for your assets. 
                                                    </p>
                                                </div>
                                            </div> 
                                            <div className='container'><Link to='/about-us' ><p className="main-btn btn-pry" href="#" style={{margin: '30px 0px 0px 5px'}}>About Us</p></Link></div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutHomeTwo;
