import React from 'react';
import thumb from '../../assets/images/fun-fact-thumb.png';
import thumb1 from '../../assets/images/fun-fact-thumb1.png';
import thumb2 from '../../assets/images/fun-fact-thumb2.png';
import thumb3 from '../../assets/images/fun-fact-thumb3.png';
import {Link,useLocation} from 'react-router-dom';

function HeroService() {
    const location = useLocation();
    const titleselect = location.pathname === '/ethical-hacking' ? 'Ethical Hacking' : location.pathname === '/forensic-investigation' ? `Forensic Investigation` : location.pathname === '/asset-recovery' ? 'Asset Recovery' : location.pathname === '/cyber-security' && 'Cyber Security' ;
    const imgselect = location.pathname === '/ethical-hacking' ? thumb : location.pathname === '/forensic-investigation' ? thumb3 : location.pathname === '/asset-recovery' ? thumb1 : location.pathname === '/cyber-security' && thumb2 ;
    const classselect = location.pathname === '/ethical-hacking' ? 'thumb' : location.pathname === '/forensic-investigation' ? 'thumb3' : location.pathname === '/asset-recovery' ? 'thumb1' : location.pathname === '/cyber-security' && 'thumb2' ;
    return (
        <>
            <div className="appie-page-title-area appie-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <span>Home{location.pathname}</span>
                                <h3 className="title">
                                   { titleselect }
                                </h3>
                                <div className={`thumb ${classselect}`}>
                                    <img src={imgselect} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroService;
