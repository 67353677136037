import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Form.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Loader from "../Helper/Loader";

function Forms() {
	const [loading, setLoading] = useState(true);
	const [name, setName] = useState(null);
	const [age, setAge] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [address, setAddress] = useState(null);
	const [message, setMessage] = useState(null);
	//Second Slide
	const [TAmountLost, setTAmountLost] = useState(null);
	const [TType, setTType] = useState("cryptocurrency");
	const [specifyType, setSpecifyType] = useState(null);
	const [DCondition, setDCondition] = useState(null);
	const [FFDescription, setFFDescription] = useState(null);
	//Third Slide
	const [SName, setSName] = useState(null);
	const [SWLink, setSWLink] = useState(null);
	const [SEmail, setSEmail] = useState(null);
	const [SPhone, setSPhone] = useState(null);
	const [SAddress, setSAddress] = useState(null);
	const [fullName, setFullName] = useState(null);
	//Validation
	const [check, setCheck] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const [finalMsg, setfinalMsg] = useState(false);
	const [nextP, setNextP] = useState(1);
	const [error, setError] = useState(1);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	});
	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (name === "name") {
			setName(value);
		}
		if (name === "age") {
			setAge(value);
		}
		if (name === "email") {
			setEmail(value);
		}
		// if (name === "phone") {
		// 	setPhone(value);
		// }
		if (name === "address") {
			setAddress(value);
		}
		if (name === "message") {
			setMessage(value);
		}
		if (type === "checkbox") {
			setCheck(checked);
		}
		if (name === "TAmountLost") {
			setTAmountLost(value);
		}
		if (name === "TType") {
			setTType(value);
		}
		if (name === "DCondition") {
			setDCondition(value);
		}
		if (name === "FFDescription") {
			setFFDescription(value);
		}
		if (name === "specify") {
			setSpecifyType(value);
		}
		if (name === "SName") {
			setSName(value);
		}
		if (name === "SWLink") {
			setSWLink(value);
		}
		if (name === "SEmail") {
			setSEmail(value);
		}
		// if (name === "SPhone") {
		// 	setSPhone(value);
		// }
		if (name === "SAddress") {
			setSAddress(value);
		}
		if (name === "fullName") {
			setFullName(value);
		}
	};
	const onsamasubSubmit = (e) => {
		e.preventDefault();
		const msg1 = `Name: ${name} , Age Range: ${age} , Email: ${email} , Number: ${phone} , Subject: ${address}`;
		const msg2 = `Total Amount Lost: ${TAmountLost} , Transaction Type: ${TType} , Specify 	Transaction type if others: ${specifyType} , Wasdevice hacked during the incident: ${DCondition} , financial fraud and how u where defrauded: ${FFDescription}`;
		const msg3 = `Subject Name: ${SName} , Subject Link: ${SWLink} , Subject Email: ${SEmail} , Subject Number: ${SPhone} , Subject Address: ${SAddress}`;
		if (
			nextP == 1 &&
			name != null &&
			// age != null &&
			address != null &&
			email != null &&
			phone != null &&
			address != null
		) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg1}`
				)

				.then((data) => {
					setSubmit(true);
				})
				.catch((e) => {
					setError(e.json());
				});
			setNextP(nextP + 1);
		}

		if (
			nextP == 2 &&
			TAmountLost != null &&
			TType != null &&
			DCondition != null &&
			FFDescription != null
			// &&
			// TType == "others" &&
			// specifyType != null
		) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg2}`
				)

				.then((data) => {
					setSubmit(true);
				})
				.catch((e) => {
					setError(e.json());
				});
			setNextP(nextP + 1);
		}
		if (
			nextP == 3 &&
			SWLink != null &&
			SPhone != null &&
			SName != null &&
			SName != null &&
			SAddress != null
		) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg3}`
				)

				.then((data) => {
					setSubmit(true);
				})
				.catch((e) => {
					setError(e.json());
				});
			setNextP(nextP + 1);
		}
		if (nextP == 4 && fullName != null) {
			setfinalMsg(true);
			setTimeout(() => {
				window.location.href = "https://t.me/polygonthresholders";
			}, 2500);
		} else {
			setSubmit(false);
			setErrorMsg(true);
			setTimeout(() => {
				setErrorMsg(false);
			}, 2000);
		}
	};
	// console.log(phone);
	return (
		<>
			{loading && (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			)}
			<section className="contact-section">
				{nextP == 1 ? (
					<div className="start-cont">
						<div className="row">
							<div className="col-md-8">
								<div className="contact-form">
									<h4>Your contact details:</h4>
									<p>These enable us to validate your information.</p>
									<form onSubmit={onsamasubSubmit} method="GET" className="row">
										<div className="col-md-6">
											<input
												type="text"
												name="name"
												placeholder="Name"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<select
												onChange={(e) => handleInputChange(e)}
												value={age}
												name="age"
											>
												<option>Select Age range</option>
												<option value="18-35">Age 18 to 35</option>
												<option value="25-45">Age 25 to 45</option>
												<option value="45-55">Age 45 to 55</option>
												<option value="55-65">Age 55 to 65</option>
												<option value="65-85">Age 65 to 85</option>
												<option value="others"> Older Than 85</option>
											</select>
										</div>
										<div className="col-md-6">
											<input
												type="email"
												name="email"
												placeholder="Johndoe@example.com"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<PhoneInput
												placeholder="Phone number"
												value={phone}
												defaultCountry="US"
												onChange={setPhone}
											/>
										</div>
										<div className="col-md-12">
											<input
												type="text"
												name="address"
												placeholder="Address"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										{errorMsg && (
											<h5 style={{ color: "red", margin: "15px" }}>
												Please Fill All information Before Clicking Next
											</h5>
										)}
										<span name="page_number" className="f_q">
											question 1 out of 4
										</span>
										<div className="col-md-8 text-right">
											<input type="submit" name="submit" value="Next" />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : nextP == 2 ? (
					<div className="start-cont">
						<div className="row">
							<div className="col-md-8">
								<div className="contact-form">
									<h4>Financial Fraud Information</h4>
									<p>
										These enable us to retrace your lost funds and conduct
										appropriate investigations.
									</p>

									<form onSubmit={onsamasubSubmit} method="GET" className="row">
										<div className="col-md-6">
											Total Amount Lost
											<input
												type="text"
												name="TAmountLost"
												placeholder="Amount Lost"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											Transaction Type
											<select
												onChange={(e) => handleInputChange(e)}
												value={TType}
												name="TType"
											>
												<option value="cryptocurrency">cryptocurrency</option>
												<option value="nft">NFT</option>
												<option value="bankcards">Credit/Debit Card</option>
												<option value="banktransfer">Bank Transfer</option>
												<option value="wiretransfer">Wire Transfer</option>
												<option value="others"> If others, specify</option>
											</select>
										</div>
										{TType === "others" && (
											<div className="col-md-12">
												<input
													type="text"
													name="specify"
													placeholder="Please Specify Here"
													onChange={(e) => handleInputChange(e)}
												/>
											</div>
										)}
										<div className="col-md-12">
											Was your device hacked during the incident ?
											<input
												type="text"
												name="DCondition"
												placeholder="Device Condition"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-12">
											<span>Provide a description of the financial fraud and how you
											were defrauded ?
											</span>
											<input
												type="text"
												name="FFDescription"
												placeholder="Financial Fraud Description"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										{errorMsg && (
											<h5 style={{ color: "red", margin: "15px" }}>
												Please Fill All information Before Clicking Next
											</h5>
										)}
										<span name="page_number" className="f_q">
											question 2 out of 4
										</span>
										<div className="col-md-8 text-right">
											<input type="submit" name="submit" value="Next" />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : nextP === 3 ? (
					<div className="start-cont">
						<div className="row">
							<div className="col-md-8">
								<div className="contact-form">
									<h4>Subject details:</h4>
									<p>
										These enable us to investigate and track down the subject.
									</p>
									<form onSubmit={onsamasubSubmit} method="GET" className="row">
										<div className="col-md-6">
											<input
												type="text"
												name="SName"
												placeholder="Subject Name"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<input
												type="text"
												name="SWLink"
												placeholder="Website Link"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<input
												type="email"
												name="SEmail"
												placeholder="Subject Email"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<PhoneInput
												placeholder="Phone number"
												value={SPhone}
												defaultCountry="US"
												onChange={setSPhone}
											/>
										</div>
										<div className="col-md-12">
											<input
												type="text"
												name="SAddress"
												placeholder="Johndoe@example.com"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										{errorMsg && (
											<h5 style={{ color: "red", margin: "15px" }}>
												Please Fill All information Before Clicking Next
											</h5>
										)}
										<span name="page_number" className="f_q">
											question 3 out of 4
										</span>
										<div className="col-md-8 text-right">
											<input type="submit" name="submit" value="Next" />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : (
					nextP == 4 && (
						<div className="start-cont">
							<div className="row">
								<div className="col-md-8">
									<div className="contact-form">
										<h4>Acknowledgement </h4>
										<p>
											Please read the following statement and ascertain your
											agreement by typing your full name in the box provided
											below: By digitally signing this document, I confirm that
											the information I provided is, to the best of my
											knowledge, true and accurate. I am aware that providing
											false information may result in a fine, imprisonment, or
											both.
										</p>
										<form
											onSubmit={onsamasubSubmit}
											method="GET"
											className="row"
										>
											<div className="col-md-12">
												<input
													type="text"
													name="fullName"
													placeholder="Digital Signature "
													onChange={(e) => handleInputChange(e)}
												/>
											</div>
											{finalMsg && (
												<h5 style={{ color: "#04AA6D", margin: "15px" }}>
													Thank you for taking your time to complete the steps.
													<p style={{ textAlign: "center", color: "#04AA6D" }}>
														Please Wait
													</p>
												</h5>
											)}
											<span
												name="page_number"
												className="f_q"
												style={{ marginLeft: "157px" }}
											>
												4 out of 4
											</span>
											{errorMsg && (
												<h5 style={{ color: "red", margin: "15px" }}>
													Please Fill All information Before Clicking Next
												</h5>
											)}
											<div className="col-md-8 text-right">
												<input type="submit" name="submit" value="Submit" />
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					)
				)}
			</section>
		</>
	);
}

export default Forms;
