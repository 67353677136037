import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
               <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                    <a style={{cursor: 'pointer'}}>
                        Service <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                    <li>
                      <Link to="/ethical-hacking">Ethical Hacking</Link>
                    </li>
                       <li>
                         <Link to="/forensic-investigation">Forensic Investigation</Link>
                        </li>
                        <li>
                         <Link to="/asset-recovery">Asset Recovery</Link>
                        </li>
                        <li>
                         <Link to="/cyber-security">Cyber Security</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a style={{cursor: 'pointer'}}>
                        About Us <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/about-us">WHY CHOOSE US</Link>
                        </li>
                        <li>
                            <Link to="/legal">Legal</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/news">Blog</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
