import React from 'react';
import thumb from '../../assets/images/service-details-thumb.jpg';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
function DetailsAbout() {
    const location = useLocation();
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                               
                                <div className="service-download-widget">
                                    <Link to="/about-us">
                                        <i className="fal fa-file"></i>
                                        <span>About Us</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/terms">
                                        <i className="fal fa-file"></i>
                                        <span>{`Terms & Conditions`}</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/privacy">
                                        <i className="fal fa-file"></i>
                                        <span>Privacy Policy</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/legal">
                                        <i className="fal fa-file"></i>
                                        <span>Legal</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">WE ARE THE THRESHOLD AGAINST CYBERCRIME.</h3>
                                    <p>
                                    Polygon Decipher is a reliable cybersecurity firm, renowned for its expertise in recovering financial assets, and handling various cyber threats posed against private entities, corporate clients, businesses as well as governmental organizations.
                                    </p>
                                    <p>
                                    At our firm, we are not only poised towards providing cybersecurity solutions but also to offer cybersecurity consulting services. With the corporate world consistently under attack, only a reliable partner can provide lasting solutions to these problems. This is where Polygon Decipher is relevant and brings its wealth of experience to light.
                                    </p>
                                    <p>
                                    As part of our obligation on providing an all-round effective Financial Fraud Recovery service, our Polygon Thresholders are relentlessly dedicated to retrieving misappropriated financial assets and digital assets that exist on the blockchain network, and other relatable financial ledger platforms. We handle each recovery case exclusively, paying attention to every detail of the cybercrime and devising the most efficient methodology to recover the lost asset.
                                    </p>
                                    <p>
                                    We pride ourselves on the quality of our workforce. Hence, our Threshold Team Pros are carefully selected from a pool of the most experienced and skilled cybersecurity experts in the industry. Working as a team, these cybersecurity experts are authorities in their respective sub-specialties, and adopt a holistic approach to combatting cybercrime. 
                                    </p>
                                    {/* <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span> */}
                                    <h3 className="title">PRODUCT AND SERVICES.</h3>
                                    <p>
                                    Our products and services ensure that individuals, private entities and businesses do not have to worry about cyber threats while carrying out their daily activities. Our duty is to shield and safeguard them by monitoring and providing professional cybersecurity services. Some of the services we offer include; Financial Asset recovery, Forensic Investigation, System Hardening, Cyber-Risk Mitigation, Technical Support, Cryptography, Advisory and Consultancy services, as well as several other Cybersecurity-related services.
                                    </p>
                                    <h3 className="title">OUR MISSION.</h3>
                                    <p>
                                    Polygon Decipher adopts only the highest standards in countering cyber threats in order to ensure that cybercrime is fully eradicated from hindering businesses and private entities from growing. As part of our mission, we serve as combats for the defenseless internet-users exposed to various cyber attacks, thereby empowering our clients and giving them a fighting chance for the future. In that essence, we are fully committed to the recovery of misappropriated and diverted financial assets and data, usually siphoned through fraudulent acts.  
                                    </p>
                                    <p>
                                    We want to create a brand that is known for providing excellent service. This will be accomplished by implementing the industrys' best practices in order to get the desired result.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsAbout;
