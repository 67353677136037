import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Privacy from './components/AboutUs/Privacy';
import Terms from './components/AboutUs/Terms';
import Legal from './components/AboutUs/Legal';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeFive from './components/HomeFive';
import News from './components/News';
import ShowBlog from './components/Blog/ShowBlog';
import SingleNews from './components/News/SingleNews';
import AssetRecovery from './components/Service/AssetRecovery';
import EthicalHacking from './components/Service/EthicalHacking';
import ForensicInvestigation from './components/Service/ForensicInvestigation';
import CyberSecurity from './components/Service/CyberSecurity';
import StartForm from './components/StartForm';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeFive} />
                            <Route exact path="/news" component={News} />
                            <Route  path="/news/:id" component={SingleNews} />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/legal" component={Legal} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/start" component={StartForm} />
                            <Route exact path="/asset-recovery" component={AssetRecovery} />
                            <Route exact path="/ethical-hacking" component={EthicalHacking} />
                            <Route exact path="/forensic-investigation" component={ForensicInvestigation} />
                            <Route exact path="/cyber-security" component={CyberSecurity} /> 
                            <Route exact path="/error" component={Error} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
