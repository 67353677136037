import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Drawer({ drawer, action }) {
    const [itemOpen, setOpen] = useState(false);
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home'); 
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        } 
       setOpen(!itemOpen) 
       if (itemOpen) {
        setSize(`0px`);
       }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a style={{cursor: 'pointer'}} onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <a style={{cursor: 'pointer'}}>Service</a>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height: item === 'service' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/ethical-hacking">Ethical Hacking</Link>
                                                </li>
                                                <li>
                                                    <Link to="/forensic-investigation">Forensic Investigation</Link>
                                                </li>
                                                <li>
                                                    <Link to="/asset-recovery">Asset Recovery</Link>
                                                </li>
                                                <li>
                                                    <Link to="/cyber-security">Cyber Security</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'about')}
                                            id="about"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <a style={{cursor: 'pointer'}}>About Us</a>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height: item === 'about' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/about-us">WHY CHOOSE US</Link>
                                                </li>

                                                <li>
                                                    <Link to="/legal">Legal</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'blog')}
                                            id="blog"
                                            className="menu-item-has-children active"
                                        >
                                                <li>
                                                    <Link to="/news">Blog</Link>
                                                </li>
                                              
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="mailto:info@polygondecipher.org">
                                                <i className="fal fa-envelope"></i>
                                                info@polygondecipher.org
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+1-617-380-3487">
                                                <i className="fal fa-phone"></i> 
                                                +1 617 380 3487
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-map-marker-alt"></i> 27 Wormwood St, South Boston, Boston, MA, 02210 United States.
                                            </a>
                                        </li>
                                    </ul>
                                    <br />
                                    <Link to="/start" className="main-btn ml-30">
                                    Start Reclaim!
									</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
