import React from 'react';
import singlePost from '../../assets/images/single-post/1.jpg';
import {useLocation} from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';

function Blog() {
    const location = useLocation(); 
    const mytitle = location.pathname.toString().substr(6);
    const [post, setPost] = useState({});
    useEffect(() => {
        axios
             .get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@polygondecipher')
 
             .then(data => {
                 // console.log(data.data)
                 const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
                     setPost(res)
             })
             .catch(e => {
                 this.setState({ error: e.toJSON() });
                 console.log(e);
             });
       }, []);
       const postFiltered = post.length >= 1 && post.filter(item => item.title === mytitle ) ;   
    return (
        <>
           <h3>{post.length >= 1 && postFiltered[0].title}</h3><hr />
           <i className="fal fa-clock"> </i> {post.length >= 1 && moment(postFiltered[0].pubDate).format("MMMM Do, YYYY")} | By {post.length >= 1 && postFiltered[0].author}
            <div className="single-post-area">
            <div
               dangerouslySetInnerHTML={{__html: post.length >= 1 && postFiltered[0].content}}
              />           
                <div className="post-share">
                    <h5>Share:</h5>
                    <a className="fac" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="twi" href={`https://twitter.com/share?u=${window.location.href}`} target="_blank">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="lin" href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
                
            </div>
            
 <br />          
        </>
    );
}

export default Blog;
