const data = {
    title: "FREQUENTLY ASKED QUESTIONS",
    rows: [
      {
        title: `WHAT ARE MY CHANCES OF RECOVERING MY FUNDS? `,
        content: "There's hardly any hiding place for cybercriminals as we have deciphered the nook and crannies of the cyberworld. Our goal is to ensure that our clients' cyber rights, stability, as well as their financial security are restored and maintained. With our advanced Threshold Team and specially configured Quantum Cybersecurity Kit, we can successfully execute the recovery of financial assets with all efficiency. "
      },
      {
        title: `WHAT DETAILS DO I NEED TO PROVIDE TO FILE A COMPLAINT?`,
        content:
          "Every tiny detail matters when gathering information about cybercrime. The victim should provide all shreds of evidence such as payment receipts, conversation receipts, contact info, as well as other information that will come in handy during the forensic investigation."
      },
      {
        title: `WILL A RECOVERY PROCEDURE COMPROMISE MY INFO OR FINANCES?`,
        content:
          "Our asset recovery service as well as other services are conducted in accordance with the cybersecurity compliance guideline. We respect the confidentiality of every clients' information and data as we adhere strictly to the code of conduct."
      },
      {
        title: "HOW MUCH DOES THE RECOVERY SERVICE COST?",
        content: "Due to the varying nature of fraud cases, no exact amount can be established. We provide a satisfactory-level service that considers the amount lost, the cyber-environment of the entire fraud, the method/technique used to siphon the funds, and the extent to which we will mobilize resources to recover the lost funds. These factors are checked and balanced to deliver an overall efficient service."
      },
    ]
  };
  
  export default data;
  