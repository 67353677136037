import React from "react";
import thumb from "../../assets/images/asset.jpeg";
import { Link, useLocation } from "react-router-dom";
function DetailsService() {
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-category-widget">
									<ul>
										<li>
											<Link to="/ethical-hacking">
												<i className="fal fa-link"></i>Ethical Hacking
											</Link>
										</li>
										<li>
											<Link to="/forensic-investigation">
												<i className="fal fa-link"></i>Forensic Investigation
											</Link>
										</li>
										<li>
											<Link to="/asset-recovery">
												<i className="fal fa-link"></i>Asset Recovery
											</Link>
										</li>
										<li>
											<Link to="/cyber-security">
												<i className="fal fa-link"></i>Cyber Security
											</Link>
										</li>
									</ul>
								</div>
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="thumb">
									<img src={thumb} alt="" />
								</div>
								<div className="content">
									<h3 className="title">
										HIGH YIELD INVESTMENT PROGRAMMES (HYIP)
									</h3>
									<p>
										It is a known fact that most HYIPs are ponzi & pyramid
										schemes that use fraudulent investment schemes to pay off
										investors. These programs pay unsustainably high returns on
										investments by using new investors money to pay off existing
										investors. Some of the common internet fraud programs
										include Identity Theft, Binary Options, Forex & CFD
										Brokerage, Prime Bank Investments, and Promissory Notes. The
										digital space has been polluted by these HYIPs, majority of
										which are operated by unlicensed individuals and
										co-operations.
									</p>
									<p>
										Their primary gimmick is to offer unbelievably high returns
										on investments, usually higher than the amount invested.
										Sadly, many individuals are ignorant about the rules that
										govern investments and they jump at these opportunities
										hoping to triple their funds within a short period of time.
									</p>
									<p>
										At Polygon Decipher, we offer victims of these pyramid
										schemes the opportunity to recover their lost funds and
										create awareness to avoid future scams. This, however, is
										dependent on certain factors, one of which is the
										availability of evidence.
									</p>
									<h3 className="title">CRYPTOCURRENCY SCAM</h3>
									<p>
										The decentralized nature and anonymity of crypto currencies
										has paved an avenue for malicious authors to exploit users.
										Although, the borderless means of payment of crypto funds
										has contributed significantly to the progress of the
										financial industry, it is also pertinent to note that this
										borderless payment scheme is also the root cause of crypto
										fraud. The emergence of crypto-based investments, such as
										NFTs, ICOs, and DeF has caused cyber fraudsters to
										infiltrate this decentralized system with their mischievous
										plot. Some of the popular shady schemes of these scammers
										include mischievous crypto swaps, fake crypto wallets,
										fraudulent NFT companies, pump and dump scheme,
										crypto-jacking, ICO fraud, and shady crypto exchanges.
									</p>
									<p>
										PolygonDecipher’s threshold team comprises veterans who will
										assess the situation and deploy effective crypto measures to
										retrieve stolen crypto assets.
									</p>
									<h3 className="title">
										CONVENTIONAL AND SOPHISTICATED SCAM RECOVERY
									</h3>
									<p>
										The emergence of technology in the global society birthed
										internet/online fraud. Today, internet scam is the most
										popular form of scam worldwide. These malicious actors have
										advanced in their techniques and developed more
										sophisticated and refined methods of scamming people. This
										has drastically increased the rate of fraudulent activities
										among these criminals. They adopt tools, such as Trojan
										virus, malware, spyware, and ransomware to speed up their
										gimmicks by gaining unauthorized access to victims’ personal
										information and devices to hijack sensitive data and
										finances from the victims. Common forms of conventional scam
										include, but are not limited to Phishing Scam, Grants Scam,
										Nigerian Prince Scam, Business Email Compromise, Inheritance
										Scam, Internet Romance Scam, Identity Theft, and Lottery.
									</p>
									<p>
										PolygonDecipher has assembled a special team of
										professionals who have mastered the techniques and styles of
										these con artists, thereby deploying effective counterfeit
										approaches to retrieve already stolen funds and combat
										future attempts.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsService;
