import React from 'react';
import {Link,useLocation} from 'react-router-dom';
function DetailsLegal() {
    const location = useLocation();
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                               
                                <div className="service-download-widget">
                                    <Link to="/about-us">
                                        <i className="fal fa-file"></i>
                                        <span>About Us</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/terms">
                                        <i className="fal fa-file"></i>
                                        <span>{`Terms & Conditions`}</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/privacy">
                                        <i className="fal fa-file"></i>
                                        <span>Privacy Policy</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/legal">
                                        <i className="fal fa-file"></i>
                                        <span>Legal</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">Introduction.</h3>
                                    <p>
                                    This disclaimer complements all the policies and guidelines on this website, which outline the norms and expectations for how Polygon Decipher conducts business in accordance with relevant laws in the operating markets and countries.
                                    </p>
                                    <h3 className="title">About The Company.</h3>
                                    <p>
                                    Polygon Decipher is a cybersecurity household that advises and assist fraud victim in recovering their stolen financial assets and data. Established in March 2020, we have acquainted ourselves with the laws of operation across countries all over the world.
                                    </p>
                                    <h3 className="title">The Services.</h3>
                                    <p>
                                    Polygon Decipher assists customers who seek our expertise and direction. We conduct our services under the supervision of licensed international attorneys. We specialize majorly in Financial Asset Recovery, which entails collaborating with our clients with the revocation and recovery of transactions through an out-of-court alternative resolution approach. The company is not required to attend any court, arbitration, or administrative procedure, and the services provided do not include court representation.
                                    </p>
                                    <p>
                                    Furthermore, Polygon Decipher cannot guarantee the outcome or outcome of recovery consulting services retained by customers.
                                    </p>
                                    <p>
                                    Consumers can get a free consultation from Polygon Decipher. Following that, recovery execution services will be commissioned, resulting in costs and/or commissions based on the service and the intricacy of each unique case.
                                    </p>
                                    <p>
                                    Cancellation of the services under the contract is in accordance with the FTC’s International Consumer Protection and Co-operation Laws.
                                    </p>
                                    <h3 className="title">{`Compliance, Regulation & Authorization.`}</h3>
                                    <p>
                                    Polygon Decipher is an authorized firm that adheres with all applicable regulatory standards in the jurisdictions we operate. Our firm complies with all Data and Financial Protection Regulation.
                                    </p>
                                    <h3 className="title">No Warranties.</h3>
                                    <p>
                                    Polygon Decipher provides services to consumers who seek assistance from us and advises them to the best of our knowledge and comprehension. The company does not represent its clients, and it cannot offer any promises concerning the outcome associated with the recovery consulting services that consumers retain.
                                    </p>
                                    <h3 className="title">Liability.</h3>
                                    <p>
                                    Polygon Decipher, its officers, directors, and employees are not responsible for any direct, indirect, consequential, or special liability arising out of or in any way particularly linked to the services, rather under the contract. While using the service, the consumer voluntarily acknowledges and assumes responsibility for himself/herself and acts for himself/herself when using the company's services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsLegal;
